<template>
  <div class="mx-3">
    <div v-if="isOpening">
      <h2 class="custom-title-secondary">
        Qual será a atividade da sua empresa?
        <span class="k-bullet">
          <base-bullet-info
            href="https://simplificador.com.br/blog/natureza-da-atividade/"
          ></base-bullet-info>
        </span>
      </h2>
    </div>
    <div v-else>
      <h2 class="custom-title-secondary">
        Qual tipo é o tipo de atividade da sua empresa?
        <span class="k-bullet">
          <base-bullet-info
            href="https://simplificador.com.br/blog/natureza-da-atividade/"
          ></base-bullet-info>
        </span>
      </h2>
    </div>
    <selectOptionsCard
      v-model="formUser.activityNature.value"
      :optionsToChoice="choices"
      :isValid="isValid"
      @onAnswer="onAnswer($event)"
      :rules="[(v) => !!v || 'escolha uma das opcões']"
    ></selectOptionsCard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import selectOptionsCard from '@components/registering/selectOptionsCard.vue'

export default {
  components: {
    selectOptionsCard,
  },
  props: {
    answer: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      currentAnswer: this.answer,
    }
  },
  computed: {
    ...mapState({
      formUser: (state) => state.formDataUser.formUser,
    }),
    ...mapGetters({
      isOpening: 'formDataUser/isOpening',
    }),
    choices() {
      return [
        {
          label: !!this.isOpening ? 'Irei prestar serviços' : 'Serviço',
          value: 'service',
          info: 'https://simplificador.com.br/blog/natureza-da-atividade/',
        },
        {
          label: !!this.isOpening
            ? 'Irei revender produtos'
            : 'Revende produtos',
          value: 'commerce',
          info: 'https://simplificador.com.br/blog/natureza-da-atividade/',
        },
        {
          label: this.isOpening
            ? 'Vou produzir e/ou vender produtos'
            : 'Produz e/ou vende produtos',
          value: 'industry',
          info: 'https://simplificador.com.br/blog/natureza-da-atividade/',
        },
      ]
    },
  },
  methods: {
    onAnswer(value) {
      this.formUser.activityNature.value = value
      this.$emit('onAnswer', {
        activityNature: this.formUser.activityNature.value,
      })
    },
  },
}
</script>

<style module lang="scss">
@import '@design';
</style>
